import { Injectable } from '@angular/core';
import { CommonFunctionService } from './common-function.service';

@Injectable({
    providedIn: 'root',
})
export class PersonalizzazioniArbiService {
    constructor(private common: CommonFunctionService) {}

    async getAssociaVariantiColonna(col_plist: string) {
        const get = await this.common.getAssociaVariantiColonna(col_plist);
        return get;
    }

    async getAssociaVarianteOpzioniColonna(col_plist: string, codvar: string) {
        const opz = await this.common.getAssociaVarianteOpzioniColonna(
            col_plist,
            codvar
        );
        return opz;
    }

    async setAssociaVariantiColonna(
        codvar: string,
        codopz: string,
        col_plist: string
    ) {
        const set = await this.common.setAssociaVariantiColonna(
            codvar,
            codopz,
            col_plist
        );
        console.log('risp set', set);
    }

    async delAssociaVarCol(codvar: string, col_plist: string) {
        const del = await this.common.delAssociaVarCol(codvar, col_plist);
        console.log('risp del', del);
    }

    async delAssociaVarOpzCol(
        codvar: string,
        codopz: string,
        col_plist: string
    ) {
        const del = await this.common.delAssociaVarOpzCol(
            codvar,
            codopz,
            col_plist
        );
        console.log('risp del', del);
    }

    async doExportRivenditori(cod_listino: string, col: any, lang: any) {
        const doExport = await this.common.doExportRivenditori(
            cod_listino,
            col,
            lang
        );
        console.log('result do export', doExport);
    }
}
