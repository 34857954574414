<ion-header class="ion-text-center ion-no-border">
    <ion-toolbar>
        <ion-title>
            Esporta
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-item mode="ios" lines="none">
        <ion-select [(ngModel)]="listinoSel" label="Seleziona il listino" labelPlacement="stacked" mode="ios">
            <ion-select-option *ngFor="let listino of listini">{{listino.COD_LISTINO}}</ion-select-option>
        </ion-select>
    </ion-item>
    <ion-item mode="ios" lines="none">
        <ion-select [(ngModel)]="colonneSel" label="Seleziona le colonne" labelPlacement="stacked" mode="ios"
            [multiple]="true">
            <ion-select-option *ngFor="let colonna of colonne">{{colonna}}</ion-select-option>
        </ion-select>
    </ion-item>
    <ion-item mode="ios" lines="none">
        <ion-select [(ngModel)]="lingueSel" label="Seleziona le lingue" labelPlacement="stacked" mode="ios"
            [multiple]="true">
            <ion-select-option *ngFor="let lingua of lingue">{{lingua}}</ion-select-option>
        </ion-select>
    </ion-item>
</ion-content>

<ion-footer class="ion-text-center ion-no-border">
    <ion-toolbar>
        <ion-button mode="ios" [disabled]="" (click)="getSelectedValues()">Esporta</ion-button>
    </ion-toolbar>
</ion-footer>