/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { CookieService } from 'ngx-cookie-service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { passiveSupport } from 'passive-events-support/src/utils.js';

import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './guards/auths-guard.service';
import { TabsComponent } from './components/tabs-menu/tabs-menu.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EditPreferenzaComponent } from './dialogs/edit-preferenza/edit-preferenza.component';
import { AggiungiPreferenzaComponent } from './dialogs/aggiungi-preferenza/aggiungi-preferenza.component';
import { NuovoListinoComponent } from './dialogs/nuovo-listino/nuovo-listino.component';
import { CommonModule } from '@angular/common';
import { CopiaListinoComponent } from './dialogs/copia-listino/copia-listino.component';
import { SimulatoreMacroComponent } from './dialogs/simulatore-macro/simulatore-macro.component';
import { RicercaArticoliComponent } from './dialogs/ricerca-articoli/ricerca-articoli.component';
import { ImportaFileComponent } from './dialogs/importa-file/importa-file.component';
import { ControlloFileImportComponent } from './dialogs/controllo-file-import/controllo-file-import.component';
import { CopiaArticoloComponent } from './dialogs/copia-articolo/copia-articolo.component';
import { SceltaImportazioneComponent } from './dialogs/scelta-importazione/scelta-importazione.component';
import { PasswordRecoveryComponent } from './dialogs/password-recovery/password-recovery.component';
import { NuovoPrezzoArticoloComponent } from './dialogs/nuovo-prezzo-articolo/nuovo-prezzo-articolo.component';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { ArticoliLinguaInsertComponent } from './dialogs/articoli-lingua-insert/articoli-lingua-insert.component';
import { AggiungiUtenteComponent } from './dialogs/aggiungi-utente/aggiungi-utente.component';
import { IntestazioniLinguaInsertComponent } from './dialogs/intestazioni-lingua-insert/intestazioni-lingua-insert.component';
import { SceltaTipoNuovoListinoComponent } from './dialogs/scelta-tipo-nuovo-listino/scelta-tipo-nuovo-listino.component';
import { NuovoListinoCalcolatoComponent } from './dialogs/nuovo-listino-calcolato/nuovo-listino-calcolato.component';
import {
    TranslateLoader,
    TranslateModule,
    TranslateService,
} from '@ngx-translate/core';
import { TranslationLoaderService } from './servizi/translations.service';
import { CreaUtenteComponent } from './dialogs/crea-utente/crea-utente.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NuovaTestataComposizioniComponent } from './dialogs/composizioni/nuova-testata-composizioni/nuova-testata-composizioni.component';
import { NuovaRigaComposizioniComponent } from './dialogs/composizioni/nuova-riga-composizioni/nuova-riga-composizioni.component';
import { ImportOExportComponent } from './dialogs/import-oexport/import-oexport.component';
import { EsportazioneComponent } from './dialogs/esportazione/esportazione.component';
import { CopiaIntestazioneComponent } from './dialogs/copia-intestazione/copia-intestazione.component';
import { SceltaEsportazioneComponent } from './dialogs/scelta-esportazione/scelta-esportazione.component';
import { ChangelogComponent } from './dialogs/changelog/changelog.component';
import { CodificatoreControlloFileComponent } from './dialogs/codificatore-controllo-file/codificatore-controllo-file.component';
import { CodificatoreImportazioneComponent } from './dialogs/codificatore-importazione/codificatore-importazione.component';
import { CodificatoreNuovaRigaComponent } from './dialogs/codificatore-nuova-riga/codificatore-nuova-riga.component';
import { CodificatoreNuovaTestataTipologiaComponent } from './dialogs/codificatore-nuova-testata-tipologia/codificatore-nuova-testata-tipologia.component';
import { CodificatoreModificaRigaComponent } from './dialogs/codificatore-modifica-riga/codificatore-modifica-riga.component';
import { NuovaTestataComponent } from './dialogs/macro/nuova-testata/nuova-testata.component';
import { NuovaRigaComponent } from './dialogs/macro/nuova-riga/nuova-riga.component';
import { ModificaRigaComponent } from './dialogs/macro/modifica-riga/modifica-riga.component';
import { AggiungiCampiCodificatoreComponent } from './dialogs/aggiungi-campi-codificatore/aggiungi-campi-codificatore.component';
import { EULAComponent } from './dialogs/eula/eula.component';
import { PRIVACYComponent } from './dialogs/privacy/privacy.component';
import { MatSliderModule } from '@angular/material/slider';
import { PrezziArticoloDistintaComponent } from './dialogs/prezzi-articolo-distinta/prezzi-articolo-distinta.component';
import { PrezziArticoloListinoComponent } from './dialogs/prezzi-articolo-listino/prezzi-articolo-listino.component';
import { MenuPage } from './pages/menu/menu.page';
import { UtentiTraduzioniComponent } from './dialogs/utenti-traduzioni/utenti-traduzioni.component';
import { PopoverComponent } from './popover/popover/popover.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ViewEulaComponent } from './dialogs/view-eula/view-eula.component';
import { ViewPrivacyComponent } from './dialogs/view-privacy/view-privacy.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SharedComponentsModule } from './components/shared-components/shared-components.module';
import { LoaderComponent } from './interceptors/loader/loader.component';
import { LoaderService } from './servizi/loader.service';
import { environment } from 'src/environments/environment';
import { CanDeactivateGuard } from './guards/CanComponentDeactivate.service';
import { EditMacroComponent } from './dialogs/macro/edit-macro/edit-macro.component';
import { initializeApp } from 'firebase/app';
import { ModificaRigaComposizioniComponent } from './dialogs/composizioni/modifica-riga-composizioni/modifica-riga-composizioni.component';
import { SetValiditaListinoComponent } from './dialogs/set-validita-listino/set-validita-listino.component';
import { UserModalComponent } from './dialogs/wordpress/user-modal/user-modal.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NuovaTestataDistintaComponent } from './dialogs/listiniCalcolatiPerDistinta/nuova-testata-distinta/nuova-testata-distinta.component';
import { NuovaRigaDistintaComponent } from './dialogs/listiniCalcolatiPerDistinta/nuova-riga-distinta/nuova-riga-distinta.component';
import { EditRigaDistintaComponent } from './dialogs/listiniCalcolatiPerDistinta/edit-riga-distinta/edit-riga-distinta.component';
import { EditTestataDistintaComponent } from './dialogs/listiniCalcolatiPerDistinta/edit-testata-distinta/edit-testata-distinta.component';
import { VarianteDerivataComponent } from './dialogs/deepspace/derive/variante-derivata/variante-derivata.component';
import { OpzioneDerivataComponent } from './dialogs/deepspace/derive/opzione-derivata/opzione-derivata.component';
import { NuovoListinoCalcolatoDistintaComponent } from './dialogs/listiniCalcolatiPerDistinta/nuovo-listino-calcolato-distinta/nuovo-listino-calcolato-distinta.component';
import { ViewerDistinteComponent } from './dialogs/listiniCalcolatiPerDistinta/viewer-distinte/viewer-distinte.component';
import { CopiaComposizioneComponent } from './dialogs/composizioni/copia-composizione/copia-composizione.component';
import { EditModelloComponent } from './dialogs/deepspace/edit-modello/edit-modello.component';
import { SelectOpzScopeComponent } from './pages/deepspace/associazione-varianti-modello/select-opz-scope/select-opz-scope.component';
import { Filter2Pipe } from './pipe/filter2.pipe';

initializeApp(environment.firebase);

import { NgxDropzoneModule } from 'ngx-dropzone';

//* deepspace

import { DoNuovoModelloComponent } from './dialogs/deepspace/do-nuovo-modello/do-nuovo-modello.component';
import { DoNuovaVarianteComponent } from './dialogs/deepspace/do-nuova-variante/do-nuova-variante.component';
import { DoNuovaOpzioneComponent } from './dialogs/deepspace/do-nuova-opzione/do-nuova-opzione.component';
import { EditVarianteComponent } from './dialogs/deepspace/edit-variante/edit-variante.component';
import { EditOpzioneComponent } from './dialogs/deepspace/edit-opzione/edit-opzione.component';

//* personalizzazioni LTFORM
import { LTFORMAssociaColonneComponent } from './dialogs/ltform/ltform-associa-colonne/ltform-associa-colonne.component';
import { LTFORMImportazioneComponent } from './dialogs/ltform/ltform-importazione/ltform-importazione.component';
import { LTFORMNuovaAssociazioneComponent } from './dialogs/ltform/ltform-nuova-associazione/ltform-nuova-associazione.component';
import { LTFORMControlloFileComponent } from './dialogs/ltform/ltform-controllo-file/ltform-controllo-file.component';
import { LtFormEsportazioneComponent } from './dialogs/ltform/lt-form-esportazione/lt-form-esportazione.component';

//* personalizzazioni MARTEDESIGN
import { MartedesignControlloFileComponent } from './dialogs/martedesign-controllo-file/martedesign-controllo-file.component';
import { MartedesignImportazioneComponent } from './dialogs/martedesign-importazione/martedesign-importazione.component';

//* personalizzazioni ALEA
import { AleaControlloFileComponent } from './dialogs/alea-controllo-file/alea-controllo-file.component';
import { AleaImportazioneComponent } from './dialogs/alea-importazione/alea-importazione.component';

//* personalizzazioni DVO
import { DvoEsportazioneComponent } from './dialogs/dvo-esportazione/dvo-esportazione.component';
import { CopiaTestataComponent } from './dialogs/macro/copia-testata/copia-testata.component';

//* personalizzazioni ITALIAN SEDIOLITI
import { SediolitiImportazioneComponent } from './dialogs/sedioliti-importazione/sedioliti-importazione.component';
import { SediolitiControlloFileComponent } from './dialogs/sedioliti-controllo-file/sedioliti-controllo-file.component';

//* personalizzazioni SANTA LUCIA
import { CreaSetSantaluciaComponent } from './dialogs/santalucia/crea-set-santalucia/crea-set-santalucia.component';
import { SantaluciaImportazioneComponent } from './dialogs/santalucia/santalucia-importazione/santalucia-importazione.component';
import { SantaluciaControlloFileComponent } from './dialogs/santalucia/santalucia-controllo-file/santalucia-controllo-file.component';
import { AssociaColonneSantaluciaComponent } from './dialogs/santalucia/associa-colonne-santalucia/associa-colonne-santalucia.component';
import { SantaluciaNuovaAssociazioneComponent } from './dialogs/santalucia/santalucia-nuova-associazione/santalucia-nuova-associazione.component';
import { ImportaComposizioniSLComponent } from './dialogs/santalucia/importa-composizioni-sl/importa-composizioni-sl.component';
import { ControlloFileComposizioniSLComponent } from './dialogs/santalucia/controllo-file-composizioni-sl/controllo-file-composizioni-sl.component';

//* personalizzazioni ARBI
import { ExportRivenditoriComponent } from './dialogs/arbi/export-rivenditori/export-rivenditori.component';
import { AddVarianteComponent } from './pages/deepspace/associazione-varianti-modello/add-variante/add-variante.component';
import { AggiungiVarianteComponent } from './dialogs/arbi/associaColonne/aggiungi-variante/aggiungi-variante.component';

passiveSupport({
    debug: false,
    listeners: [
        {
            element: 'div.some-element',
            event: 'touchstart',
        },
    ],
});

export const createTranslateLoader = () => new TranslationLoaderService();

@NgModule({
    declarations: [
        AppComponent,
        EditPreferenzaComponent,
        AggiungiPreferenzaComponent,
        NuovoListinoComponent,
        CopiaListinoComponent,
        SimulatoreMacroComponent,
        RicercaArticoliComponent,
        ImportaFileComponent,
        ControlloFileImportComponent,
        CopiaArticoloComponent,
        SceltaImportazioneComponent,
        PasswordRecoveryComponent,
        NuovoPrezzoArticoloComponent,
        ArticoliLinguaInsertComponent,
        IntestazioniLinguaInsertComponent,
        AggiungiUtenteComponent,
        SceltaTipoNuovoListinoComponent,
        NuovoListinoCalcolatoComponent,
        LTFORMAssociaColonneComponent,
        LTFORMImportazioneComponent,
        LTFORMNuovaAssociazioneComponent,
        LTFORMControlloFileComponent,
        LtFormEsportazioneComponent,
        SediolitiImportazioneComponent,
        SediolitiControlloFileComponent,
        CreaSetSantaluciaComponent,
        SantaluciaImportazioneComponent,
        SantaluciaControlloFileComponent,
        ImportaComposizioniSLComponent,
        ControlloFileComposizioniSLComponent,
        SantaluciaNuovaAssociazioneComponent,
        AssociaColonneSantaluciaComponent,
        CreaUtenteComponent,
        NuovaTestataComposizioniComponent,
        NuovaRigaComposizioniComponent,
        ModificaRigaComposizioniComponent,
        ImportOExportComponent,
        EsportazioneComponent,
        MartedesignControlloFileComponent,
        MartedesignImportazioneComponent,
        CopiaIntestazioneComponent,
        SceltaEsportazioneComponent,
        DoNuovoModelloComponent,
        DoNuovaVarianteComponent,
        DoNuovaOpzioneComponent,
        AleaControlloFileComponent,
        AleaImportazioneComponent,
        ChangelogComponent,
        CodificatoreControlloFileComponent,
        CodificatoreImportazioneComponent,
        CodificatoreNuovaRigaComponent,
        CodificatoreNuovaTestataTipologiaComponent,
        CodificatoreModificaRigaComponent,
        NuovaTestataComponent,
        NuovaRigaComponent,
        ModificaRigaComponent,
        DvoEsportazioneComponent,
        AggiungiCampiCodificatoreComponent,
        EULAComponent,
        PRIVACYComponent,
        PrezziArticoloDistintaComponent,
        PrezziArticoloListinoComponent,
        MenuPage,
        TabsComponent,
        UtentiTraduzioniComponent,
        PopoverComponent,
        ViewEulaComponent,
        ViewPrivacyComponent,
        LoaderComponent,
        EditMacroComponent,
        CopiaTestataComponent,
        SetValiditaListinoComponent,
        UserModalComponent,
        EditVarianteComponent,
        EditOpzioneComponent,
        NuovaTestataDistintaComponent,
        NuovaRigaDistintaComponent,
        EditRigaDistintaComponent,
        EditTestataDistintaComponent,
        VarianteDerivataComponent,
        OpzioneDerivataComponent,
        NuovoListinoCalcolatoDistintaComponent,
        ViewerDistinteComponent,
        ExportRivenditoriComponent,
        CopiaComposizioneComponent,
        EditModelloComponent,
        SelectOpzScopeComponent,
        AddVarianteComponent,
        AggiungiVarianteComponent,

        // MenuVaultComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        IonicModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
            },
            defaultLanguage: 'en',
        }),
        AppRoutingModule,
        HttpClientModule,
        LayoutModule,
        MatDialogModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        NgxExtendedPdfViewerModule,
        Ng2SearchPipeModule,

        CdkTreeModule,
        MatTreeModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        DragDropModule,
        MatGridListModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatSliderModule,
        MatTooltipModule,
        MatPaginatorModule,
        SharedComponentsModule,
        MatProgressSpinnerModule,
        NgxDropzoneModule,
    ],
    providers: [
        //// { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },

        AuthGuard,
        CanDeactivateGuard,
        CookieService,
        LoaderService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true,
        },
    ],
    entryComponents: [
        EditPreferenzaComponent,
        AggiungiPreferenzaComponent,
        NuovoListinoComponent,
        CopiaListinoComponent,
        SimulatoreMacroComponent,
        RicercaArticoliComponent,
        ImportaFileComponent,
        ControlloFileImportComponent,
        CopiaArticoloComponent,
        SceltaImportazioneComponent,
        PasswordRecoveryComponent,
        NuovoPrezzoArticoloComponent,
        ArticoliLinguaInsertComponent,
        IntestazioniLinguaInsertComponent,
        AggiungiUtenteComponent,
        SceltaTipoNuovoListinoComponent,
        NuovoListinoCalcolatoComponent,
        LTFORMAssociaColonneComponent,
        LTFORMImportazioneComponent,
        LTFORMNuovaAssociazioneComponent,
        LTFORMControlloFileComponent,
        CreaUtenteComponent,
        NuovaTestataComposizioniComponent,
        NuovaRigaComposizioniComponent,
        ImportOExportComponent,
        EsportazioneComponent,
        MartedesignControlloFileComponent,
        MartedesignImportazioneComponent,
        CopiaIntestazioneComponent,
        SceltaEsportazioneComponent,
        AleaControlloFileComponent,
        AleaImportazioneComponent,
    ],
    bootstrap: [AppComponent],
    exports: [RouterModule],
})
export class AppModule {
    constructor(public translate: TranslateService) {
        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang('en');

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        translate.use('en');
    }
}

//platformBrowserDynamic().bootstrapModule(AppModule);
