<ion-header class="ion-no-border">
    <ion-toolbar>
        <ion-title>Collega una variante</ion-title>
        <ion-icon style="float: right;" color="primary" name="close-outline" (click)="modalCtrl.dismiss()"></ion-icon>
    </ion-toolbar>
</ion-header>

<ion-content>

    <ion-grid>
        <ion-row>
            <ion-col>
                <input class="searchbar" type="text" name="search" placeholder="cerca..." [(ngModel)]="varSearch"
                    autocomplete="off">
            </ion-col>
        </ion-row>
        <ion-row class="colored" *ngFor="let v of varianti | filter: varSearch" (click)="addVariante(v)">
            <ion-col>
                <b>{{v.CODVAR}}</b> {{v.DESVAR}}
            </ion-col>
        </ion-row>
    </ion-grid>


</ion-content>
