<ion-header class="ion-no-border">
    <ion-toolbar>
        <ion-title>{{'Copia testata' + ' ' + this.cod_start}}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>

    <form [formGroup]="fg">
        <ion-item mode="ios">
            <ion-input label="Testata in cui copiare" labelPlacement="stacked" type="text"
                formControlName="cod_end"></ion-input>
        </ion-item>
        <ion-item mode="ios">
            <ion-input [label]="this.translate.instant('090')" labelPlacement="stacked" type="text"
                formControlName="des_macro"></ion-input>
        </ion-item>
        <ion-item mode="ios">
            <ion-input [label]="this.translate.instant('085')" labelPlacement="stacked" type="text"
                formControlName="codalt"></ion-input>
        </ion-item>
        <ion-item mode="ios">
            <ion-input [label]="this.translate.instant('107')" labelPlacement="stacked" type="text"
                formControlName="diml"></ion-input>
        </ion-item>
        <ion-item mode="ios">
            <ion-input [label]="this.translate.instant('108')" labelPlacement="stacked" type="text"
                formControlName="dima"></ion-input>
        </ion-item>
        <ion-item mode="ios">
            <ion-input [label]="this.translate.instant('109')" labelPlacement="stacked" type="text"
                formControlName="dimp"></ion-input>
        </ion-item>
    </form>

</ion-content>

<ion-footer class="ion-no-border">
    <ion-toolbar class="ion-text-center">
        <ion-button mode='ios' type="submit" [disabled]="!fg.valid" (click)="copiaTestata()">Copia</ion-button>
    </ion-toolbar>
</ion-footer>