import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlertController, ViewWillEnter } from '@ionic/angular';
import { ListiniService } from 'src/app/servizi/listini.service';
import { PersonalizzazioniArbiService } from 'src/app/servizi/personalizzazioni-arbi.service';
import { PreferenzeService } from 'src/app/servizi/preferenze.service';
import { listiniStruct } from 'src/app/strutture/listini.strutture';

@Component({
    selector: 'app-export-rivenditori',
    templateUrl: './export-rivenditori.component.html',
    styleUrls: ['./export-rivenditori.component.scss'],
})
export class ExportRivenditoriComponent implements ViewWillEnter {
    listini: listiniStruct[] = [];
    colonne: string[] = [];
    lingue: string[] = [];

    listinoSel: string = '';
    colonneSel: string[] = [];
    lingueSel: string[] = ['IT'];

    constructor(
        private ls: ListiniService,
        private pas: PersonalizzazioniArbiService,
        private ps: PreferenzeService,
        private alertCtrl: AlertController
    ) {}

    ionViewWillEnter() {
        this.getListini();
        this.getColonne();
        this.getLingue();
    }

    async getListini() {
        this.listini = await this.ls.getListini();
    }

    async getColonne() {
        let preferenzeCat = await this.ps.getPreferenzeCatalogo();
        let colonne = preferenzeCat.filter(
            (el) => el.COD_PREFERENZA === 'COLONNE_PRICELIST'
        );
        this.colonne = colonne[0].VALORE_PREFERENZA.split(',').sort();
    }

    async getLingue() {
        let preferenzeCat = await this.ps.getPreferenzeCatalogo();
        console.log('pref', preferenzeCat);
        let lingue = preferenzeCat.filter(
            (el) => el.COD_PREFERENZA === 'CAMPI_LINGUA'
        );
        this.lingue = lingue[0].VALORE_PREFERENZA.split(',').sort();
    }

    async getSelectedValues() {
        const selectedValues = {
            listino: this.listinoSel,
            colonne: this.colonneSel,
            lingue: this.lingueSel,
        };

        console.log('Selezioni:', selectedValues);

        if (
            selectedValues.listino !== '' &&
            selectedValues.colonne.length > 0 &&
            selectedValues.lingue.length > 0
        ) {
            console.log('ho tutti i valori, posso fare export');
            this.doExport();
        } else {
            console.log('non ho i valori, non proseguo');
            const alert = await this.alertCtrl.create({
                mode: 'ios',
                header: 'Attenzione!',
                message: 'Bisogna selezionare almeno un valore',
                buttons: [
                    {
                        text: 'Ok',
                        role: 'confirm',
                    },
                ],
            });
            await alert.present();
            await alert.onDidDismiss();
        }
    }

    async doExport() {
        const resp = await this.pas.doExportRivenditori(
            this.listinoSel,
            JSON.stringify(this.colonneSel),
            JSON.stringify(this.lingueSel)
        );
        console.log('response do export', resp);
    }
}
