import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from '../search/search.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { GeneralHeaderComponent } from '../general-header/general-header.component';
import { RouterModule } from '@angular/router';
import { Filter2Pipe } from 'src/app/pipe/filter2.pipe';

@NgModule({
    declarations: [SearchComponent, GeneralHeaderComponent, Filter2Pipe],
    imports: [CommonModule, IonicModule, FormsModule, RouterModule],
    exports: [SearchComponent, GeneralHeaderComponent, Filter2Pipe],
})
export class SharedComponentsModule {}
