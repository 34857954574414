import { Component, OnInit } from '@angular/core';
import { ModalController, ViewWillEnter } from '@ionic/angular';
import { PersonalizzazioniArbiService } from 'src/app/servizi/personalizzazioni-arbi.service';
import { VariantiService } from 'src/app/servizi/varianti.service';
import { assocColVarStruct } from 'src/app/strutture/assocColVar.strutture';
import { variantiStruct } from 'src/app/strutture/varianti.strutture';

@Component({
    selector: 'app-aggiungi-variante',
    templateUrl: './aggiungi-variante.component.html',
    styleUrls: ['./aggiungi-variante.component.scss'],
})
export class AggiungiVarianteComponent implements OnInit, ViewWillEnter {
    varianti: any;
    variantiTot: variantiStruct[] = [];
    variantiAssoc: assocColVarStruct[] = [];

    varSearch: string = '';

    col_plist: any;

    constructor(
        public modalCtrl: ModalController,
        private vs: VariantiService,
        private pas: PersonalizzazioniArbiService
    ) {}

    async ionViewWillEnter() {
        await this.getVarianti();
        await this.getVariantiAssoc();

        const codvarAssoc = this.variantiAssoc.map((item) => item.CODVAR);
        this.varianti = this.variantiTot.filter(
            (vars) => !codvarAssoc.includes(vars.CODVAR)
        );
    }

    ngOnInit() {}

    async getVarianti() {
        this.variantiTot = await this.vs.getVarianti();
        console.log('varianti', this.variantiTot);
    }

    async getVariantiAssoc() {
        this.variantiAssoc = await this.pas.getAssociaVariantiColonna(
            this.col_plist
        );
        console.log('varianti assoc', this.variantiAssoc);
    }

    async addVariante(v: variantiStruct) {
        this.modalCtrl.dismiss(v);
    }
}
